import React, { useRef, useEffect } from "react";
import { v1 as uuid } from "uuid";
import io from "socket.io-client";
import logo from './assets/logoservinform.png';
import loading from './assets/loadingserv.gif';

const CreateRoom = (props) => {
    var id = uuid();
    const partnerVideo = useRef();
    const userStream = useRef();
    const userVideo = useRef();
    const peerRef = useRef();
    const socketRef = useRef();
    const otherUser = useRef();
    var peer;
    var isSafari;
    const url = window.location.origin + "/room/" + id;
    var mensaje = "Entra en el siguiente enlace para la videollamada con Servinform: " + url;
    var video;
    var context;
    var canvas;
    var myWidth;
    var myHeight;
    var ratio;

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(stream => {
            userVideo.current.srcObject = stream;
            userStream.current = stream;

            socketRef.current = io.connect("/");
            socketRef.current.emit("join room", id);

            socketRef.current.on('other user', userID => {
                callUser(userID);
                otherUser.current = userID;
            });

            socketRef.current.on("user joined", userID => {
                otherUser.current = userID;
            });

            socketRef.current.on("offer", handleRecieveCall);

            socketRef.current.on("answer", handleAnswer);

            socketRef.current.on("ice-candidate", handleNewICECandidateMsg);
        }).catch(function(err) {
            alert("Se necesita disponer de un micrófono y permitir al navegador su acceso. Vuelve a intentarlo.");
          });

        video = document.getElementById('videmisor');
        canvas = document.querySelector('canvas');
        context = canvas.getContext('2d');
        canvas.style.display = "none";

        video.addEventListener('loadedmetadata', function(){
            ratio = video.videoWidth/video.videoHeight;
       
            myWidth = 2880;
            myHeight = 3840;
            canvas.width = 2880;
            canvas.height = 3840;
            
        }, false);

    }, []);

    function callUser(userID) {
        peerRef.current = createPeer(userID);
        userStream.current.getTracks().forEach(track => peerRef.current.addTrack(track, userStream.current));
    }

    function createPeer(userID) {
        const peer = new RTCPeerConnection({
            iceServers: [
                {
                    urls: ["stun:eu-turn3.xirsys.com"]
                }, {
                    username: "hXJOMNBJpQFkbQPCQgfzhLeKeO3Pv_XtnMc3WKdVCLtLSruS-NqFbFmjb7e9jkKxAAAAAGCvdN1zZXJ2aW5mb3Jt",
                    credential: "9d6d699a-bed6-11eb-bb39-0242ac140004",
                    urls: [
                        "turn:eu-turn3.xirsys.com:80?transport=udp",
                        "turn:eu-turn3.xirsys.com:3478?transport=udp",
                        "turn:eu-turn3.xirsys.com:80?transport=tcp",
                        "turn:eu-turn3.xirsys.com:3478?transport=tcp",
                        "turns:eu-turn3.xirsys.com:443?transport=tcp",
                        "turns:eu-turn3.xirsys.com:5349?transport=tcp"
                    ]
                }
            ]
        });

        peer.onicecandidate = handleICECandidateEvent;
        peer.ontrack = handleTrackEvent;
        peer.onnegotiationneeded = () => handleNegotiationNeededEvent(userID);

        return peer;
    }

    function handleNegotiationNeededEvent(userID) {
        peerRef.current.createOffer().then(offer => {
            return peerRef.current.setLocalDescription(offer);
        }).then(() => {
            const payload = {
                target: userID,
                caller: socketRef.current.id,
                sdp: peerRef.current.localDescription
            };
            socketRef.current.emit("offer", payload);
        }).catch(e => console.log(e));
    }

    function handleRecieveCall(incoming) {
        peerRef.current = createPeer();
        const desc = new RTCSessionDescription(incoming.sdp);
        peerRef.current.setRemoteDescription(desc).then(() => {
            userStream.current.getTracks().forEach(track => peerRef.current.addTrack(track, userStream.current));
        }).then(() => {
            return peerRef.current.createAnswer();
        }).then(answer => {
            return peerRef.current.setLocalDescription(answer);
        }).then(() => {
            const payload = {
                target: incoming.caller,
                caller: socketRef.current.id,
                sdp: peerRef.current.localDescription
            }
            socketRef.current.emit("answer", payload);
        })
    }

    function handleAnswer(message) {
        const desc = new RTCSessionDescription(message.sdp);
        peerRef.current.setRemoteDescription(desc).catch(e => console.log(e));
    }

    function handleICECandidateEvent(e) {
        if (e.candidate) {
            const payload = {
                target: otherUser.current,
                candidate: e.candidate,
            }
            socketRef.current.emit("ice-candidate", payload);
        }
    }

    function handleNewICECandidateMsg(incoming) {
        const candidate = new RTCIceCandidate(incoming);

        peerRef.current.addIceCandidate(candidate)
            .catch(e => console.log(e));
    }

    function handleTrackEvent(e) {
        partnerVideo.current.srcObject = e.streams[0];
    };

    function hacerCaptura(){  
        context.fillRect(0,0,myWidth, myHeight);
        context.drawImage(video, 0, 0, myWidth, myHeight);
        var url = canvas.toDataURL("image/png");
        var link = document.createElement('a');
        var today = new Date();
        var mes;
        var dia;
        var mesactual = today.getMonth() + 1;
        if(mesactual < 10){
            mes = '0' + mesactual;
        }else{
            mes = today.getMonth() + 1;
        }
        if(today.getDate() < 10){
            dia = '0' + today.getDate();
        }else{
            dia = today.getDate();
        }
        var date = today.getFullYear() + '' + mes+ '' + dia;
        var time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
        link.download = date + '' + time + '.png';
        link.href = url;
        link.click();
    };

    function peerClos(){
        peer.close();
        peerRef.close();
    }

    isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

        return (
            <table width="100%" class="tablacentro">
                <tr>
                    <td>
                        <img width="360" class="logoservinform" height="121" src={logo}></img><br></br>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        {isSafari ? <video id="videmisor" width="640" height="480" controls autoPlay ref={partnerVideo} poster={loading} playsInline /> : <video id="videmisor"  width="640" height="480" autoPlay ref={partnerVideo} poster={loading} playsInline /> } <br></br>
                        <button onClick={hacerCaptura} name="bot_sms" class="btn btn-primary" id="botcaptura">
                            <span class="material-icons" id="spancam">
                                local_see
                            </span>
                            &nbsp; Captura
                        </button>
                        <a onClick={peerClos} href="/admin/W5bMO5Ia" class="btn btn-danger">
                            <span class="material-icons" id="spancam">
                                phone_disabled
                            </span>
                            &nbsp; Colgar
                        </a>
                        <video class="vidcentral" autoPlay playsInline muted ref={userVideo} />
                        <canvas id="canvas" width="2880" height="3840"></canvas>
                    </td>
                    <td>
                        <form method="POST" action="https://contacto.servinformcrm.com/servinform/controller.php" target="_blank" name="formdata">
                            <label align="right">Enviar enlace al cliente:</label>
                            <div class="col-auto">
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="material-icons">
                                                home
                                            </span>
                                        </div>
                                    </div>
                                    <input name="from" required type="text" class="form-control" placeholder="Remitente" defaultValue="Servinform"/>
                                </div>
                            </div>
    
                            <br/>
    
                            <div class="col-auto">
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="material-icons">
                                                call
                                            </span>
                                        </div>
                                    </div>
                                    <input name="tel" type="tel" class="form-control" placeholder="Teléfono"/>
                                </div>
                            </div>
    
                            <br/>

                            <div class="col-auto">
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="material-icons">
                                                alternate_email
                                            </span>
                                        </div>
                                    </div>
                                    <input type="email" class="form-control" name="email" placeholder="Correo electrónico"/>
                                </div>
                            </div>
    
                            <br/>
    
                            <div class="col-auto">
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="material-icons">
                                                email
                                            </span>
                                        </div>
                                    </div>
                                    <textarea name="msg" required placeholder="Mensaje" class="form-control" defaultValue={mensaje} rows="5"></textarea>
                                </div>
                            </div>
    
                            <br/>
    
                            <input name="pass" type="hidden" value="1234!Z"/>
                            <input name="url" type="hidden" value={url}/>
    
                            <br/>
    
                            <button type="submit" name="bot_sms" class="btn btn-primary">Enviar SMS</button>

                            <button type="submit" name="bot_email" class="btn botcorreo btn-info">Enviar correo electrónico</button>
                        </form>
                        {/* <form method="POST" action="https://editajob.com/sms/index.php" target="_blank">
                            <input value="admin1234" type="hidden" name="pass"></input>
                            <input value={id} type="hidden" name="url"></input>
                        <button type="submit" class="btn btn-success" >Enviar</button>
                        </form> */}
                    </td>
                </tr>
            </table>
        );
}

export default CreateRoom;