import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CreateRoom from "./routes/CreateRoom";
import CreateRoombi from "./routes/CreateRoombi";
import ErrorNavegador from "./routes/ErrorNavegador";
import ErrorNavegadorbi from "./routes/ErrorNavegadorbi";
import Room from "./routes/Room";
import Roombi from "./routes/Roombi";
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={() => { window.location = 'https://www.servinform.es'; return null;} }/>
          <Route path="/:aleatorio" exact component={() => { window.location = 'https://www.servinform.es'; return null;} }/>
          <Route path="/admin/W5bMO5Ia" component={CreateRoom} />
          <Route path="/room/:roomID" component={Room} />
          <Route path="/adminbi/W5bMO5Ia" component={CreateRoombi} />
          <Route path="/adminbi/tke" component={CreateRoombi} />
          <Route path="/roombi/:roomID" component={Roombi} />
          <Route path="/errornavegador/:roomID" component={ErrorNavegador} />
          <Route path="/errornavegadorbi/:roomID" component={ErrorNavegadorbi} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
